import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Container, Section } from "../components/utils"
import SEO from "../components/seo"
import Hero from "../components/servicehero"
import styled from "styled-components"
import Check from "../images/check-solid.svg"
import Cta from "../components/cta"
import Lightbox from "../components/lightbox"

const IndexPage = ({ data }) => {
  const {
    title,
    summaryQuote,
    image,
    gallery,
    content,
    shortDescription,
    servicesIncludeNode,
    didYouKnowNode,
  } = data.service

  const [selectedImage, selectImage] = useState("")
  return (
    <Layout>
      <SEO title={title} />
      <Hero image={image}>
        <h1>{title}</h1>
        <p>{shortDescription}</p>

        <Link className="button" to={`/contact`}>
          Speak to us today
        </Link>
      </Hero>
      {summaryQuote && (
        <Section>
          <Container>
            <SummaryQuote>&ldquo;{summaryQuote}&rdquo;</SummaryQuote>
          </Container>
        </Section>
      )}
      <Section color="paleBlue" padding="medium">
        <Container>
          <h2 className="no-line lowercase">Our {title} services include:</h2>
          <IncludedServices
            dangerouslySetInnerHTML={{
              __html: servicesIncludeNode.childMarkdownRemark.html,
            }}
          />
        </Container>
      </Section>
      {content && (
        <Section padding="medium">
          <Container>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Container>
        </Section>
      )}
      {didYouKnowNode.childMarkdownRemark.html && (
        <Section padding="medium" color="paleBlue">
          <Container>
            <h2>Did you know?</h2>
            <DidYouKnow
              dangerouslySetInnerHTML={{
                __html: didYouKnowNode.childMarkdownRemark.html,
              }}
            />
          </Container>
        </Section>
      )}
      {gallery.length > 0 && (
        <Section padding="medium">
          <Lightbox image={selectedImage} selectImage={selectImage} />
          <Container>
            <h2 className="no-line lowercase">Examples of our work</h2>
            <Gallery>
              {gallery.map(image => (
                <button
                  className="image-wrapper"
                  onClick={() => selectImage(image)}
                >
                  <div className="overlay" />
                  <Img fluid={image.fluid} alt={image.alt} />
                </button>
              ))}
            </Gallery>
          </Container>
        </Section>
      )}
      <Cta />
    </Layout>
  )
}
export default IndexPage

const SummaryQuote = styled.h3`
  color: ${({ theme }) => theme.orange};
  font-size: 1.8rem;
  text-align: center;
  text-transform: none;
  &::after {
    display: block;
    content: "";
    margin-top: 2rem !important;
    width: 100%;
    border-bottom: solid 2px ${props => props.theme.orange};
  }
`
const IncludedServices = styled.div`
  li {
    list-style: none;
    position: relative;
    font-size: 1.4rem;
    &:not(last-of-type) {
      margin-bottom: 1rem;
    }
    &::before {
      position: absolute;
      left: -30px;
      width: 20px;
      height: 30px;
      content: "";
      background: url(${Check}) no-repeat center;
    }
  }
`
const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  grid-gap: 1rem;
  .image-wrapper {
    position: relative;
    cursor: pointer;
    .gatsby-image-wrapper {
      height: 100%;
    }
    &::after {
      position: absolute;
      content: "+";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity ease 0.3s;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
`
const DidYouKnow = styled.div`
  p {
    font-size: 1.3rem;
    font-style: italic;
  }
`

export const query = graphql`
  query($slug: String!) {
    service: datoCmsService(slug: { eq: $slug }) {
      content
      title
      summaryQuote
      shortDescription
      didYouKnowNode {
        childMarkdownRemark {
          html
        }
      }
      servicesIncludeNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      gallery {
        fluid(maxWidth: 1000) {
          ...GatsbyDatoCmsFluid
        }
        alt
        filename
      }
    }
  }
`
