import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Lightbox = ({ image, selectImage }) => {
  return (
    <Modal onClick={() => selectImage("")} visible={image}>
      {image && <ModalImg fluid={image.fluid} />}
    </Modal>
  )
}

export default Lightbox

const Modal = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? `block` : `none`)};
  z-index: 100;
`
const ModalImg = styled(Img)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 900px;
  z-index: 200;
  &:after {
    content: "x";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`
