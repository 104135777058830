import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Section, Container } from "./utils"
import styled from "styled-components"

const BackgroundSection = styled(Section)`
  background-image: linear-gradient(
      to right,
      rgba(20, 20, 20, 0.74),
      rgba(255, 255, 255, 0)
    ),
    url(${props => props.image});
  background-size: cover;
  background-position: center;
  h2 {
    font-size: 3rem;
    max-width: 500px;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 4rem;
    max-width: 500px;
    font-size: 1.2rem;
  }
`

const Cta = () => {
  const data = useStaticQuery(graphql`
    {
      page: datoCmsServicesPage {
        callToActionImage {
          fluid {
            src
          }
        }
      }
    }
  `)
  console.log(data.page.callToActionImage.fluid.src)

  return (
    <BackgroundSection
      padding="large"
      image={data.page.callToActionImage.fluid.src}
    >
      <Container>
        <h2 className="text-white">Book a free consultation</h2>
        <p className="text-white">
          Get in touch with one of our team for a personalised quote or with any
          questions you may have about our services.
        </p>
        <Link to="/contact" className="button">
          Contact Us
        </Link>
      </Container>
    </BackgroundSection>
  )
}
export default Cta
